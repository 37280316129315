
// Vue
import {Options, Vue} from "vue-class-component";

// Components
import {
  ElRow,
  ElCol,
} from "element-plus";
import PlayerNavigationVue from "@/components/player/navigation.vue";
import AppPlayerNotesRootListIndexVue from "@/views/app/player/notes/root/list/index.vue";

@Options({
  components: {
    ElRow,
    ElCol,
    PlayerNavigationVue,
    AppPlayerNotesRootListIndexVue,
  },
})
export default class AppPlayerNotesRootIndexVue extends Vue {}
