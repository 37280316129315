// Store
import store from "@/store/index";

export interface AppPlayerNotesRootListFormCreate {
  user_id: string;
  organization_id: string;
  content: {
    quality_of_sleep: string;
    quality_of_diet: string;
    observed_pains: string;
    tiredness: string;
    description: string;
  };
}

export class AppPlayerNotesRootListFormCreate implements AppPlayerNotesRootListFormCreate {
  user_id: string;
  organization_id: string;
  content: {
    quality_of_sleep: string;
    quality_of_diet: string;
    observed_pains: string;
    tiredness: string;
    description: string;
  };

  constructor() {
    this.user_id = store.getters.getAuthentication.id ?? "";
    this.organization_id = store.getters.getOrganizationMember.organization_id ?? "";
    this.content = {
      quality_of_sleep: "",
      quality_of_diet: "",
      observed_pains: "",
      tiredness: "",
      description: "",
    };
  }
}